/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query {
    katastatiko: allFile(
      filter: { relativePath: { eq: "data/katastatiko.pdf" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    },
    banner: allFile(
      filter: { relativePath: { eq: "data/banner.jpeg" } }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

const IndexPage = props => {
  const katastatiko = props.data.katastatiko.edges
  const bannerUrl = props.data.banner.edges?.[0].node.publicURL

  return (
    <Layout>
      <Head title="Home" />
      <div className="centered">
        {/* todo: to be removed when the program is over */}
        <div className="flex flex-col gap-4 items-center">
          <p className="text-sm text-center">
            Η επιχείρησή χρηματοδοτείται από το «Πρόγραμμα επιχορήγησης επιχειρηματικών πρωτοβουλιών απασχόλησης νέων ελεύθερων επαγγελματιών ηλικίας 18 έως 29 ετών με
            έμφαση στις γυναίκες» του Επιχειρησιακού Προγράμματος "Ανάπτυξη Ανθρώπινου Δυναμικού, Εκπαίδευση & Δια Βίου Μάθηση", με συγχρηματοδότηση από το Ευρωπαϊκό Κοινωνικό Ταμείο και Πρωτοβουλία για την Απασχόληση των Νέων.
          </p>
          <img className="max-w-[750px]" src={bannerUrl} />
        </div>

        <p>
          Η Αλέξανδρος Βασιλείου Μονοπρόσωπη Ι.Κ.Ε. δραστηριοποιείται στην
          παροχή υπηρεσιών εφαρμογών πληροφορικής.
        </p>
        <ul className="vim-categories">
          Βασικές πληροφορίες
          <li>
            Όνομα εταιρίας: <span>Αλέξανδρος Βασιλείου Μονοπρόσωπη Ι.Κ.Ε.</span>
          </li>
          <li>
            Διαχειριστής και μοναδικός εταίρος:
            <span> Αλέξανδρος Βασιλείου</span>
          </li>
          <ul>
            Στοιχεία επικοινωνίας:
            <li>
              Διεύθυνση: <span>Μεγάλου Αλεξάνδρου 35, 41222 Λάρισα</span>
            </li>
            <li>
              Τηλέφωνο: <span>2411810849</span>
            </li>
            <li>
              email: <span>alexandrosvasileiou@gmail.com</span>
            </li>
          </ul>
        </ul>
        <ul className="vim-categories">
          Ιδιοκτησία και Οικονομικά Στοιχεία
          <li>
            ΑΦΜ:
            <span> 801648013</span>
          </li>
          <li>
            ΔΟΥ:
            <span> Λάρισας</span>
          </li>
          <li>
            Εταιρικό Κεφάλαιο:
            <span> 3000€</span>
          </li>
          <li>
            Νομική Μορφή:
            <span> Μονοπρόσωπη Ι.Κ.Ε.</span>
          </li>
          <li>
            Διάρκεια Εταιρίας:
            <span> 12ετής</span>
          </li>
          <li>
            Αριθμός ΓΕΜΗ:
            <span> 160789940000</span>
          </li>
          <li>
            Ημερομηνία Σύστασης:
            <span> 21/09/2021</span>
          </li>
          <ul>
            Καταστατικό - Τροποποιήσεις:
            <li>
              {katastatiko.map(({ node }, index) => (
                <a href={node.publicURL} target="_blank" key={index}>
                  Κατεβάστε το αρχείο
                </a>
              ))}
            </li>
          </ul>
        </ul>

        <ul className="vim-categories">
          Περιγραφή των εργασιών της εταιρίας
          <li>Η παροχή υπηρεσιών εφαρμογών πληροφορικής</li>
          <li>Η παροχή υπηρεσιών γραφίστα ή μακετίστα, εκτός διαφήμισης</li>
          <li>Η κατασκευή παιχνιδιών κάθε είδους (Π.Δ.Κ.Α.)</li>
          <li>
            Λιανικό εμπόριο παιχνιδιών κάθε είδους, με αλληλογραφία ή μέσω
            διαδικτύου
          </li>
          <li>Η έκδοση λογισμικού εφαρμογών που κατεβάζεται από το internet</li>
          <li>Η παραγωγή πρωτότυπων λογισμικού</li>
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage
